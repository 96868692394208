import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Text from '../Text'
import GlobalContext from '../GlobalContext'
import TextIcon from '../TextIcon'
import dynamic from 'next/dynamic'
const DeliveryModeSwitchPopup = dynamic(() =>
  import('../DeliveryModeSwitchPopup')
)
import { SCREEN_SIZE } from '../../lib/Media'
import Expand from '../icons/ExpandNew'
import { SPLIT_FEATURES } from '../../utils/constants'
import { getExpConfig } from '../../utils/configService'

const StyledPcmForm = styled.div`
  ${SCREEN_SIZE.From.Tablet} {
    position: relative;
  }
`

const SelectContainer = styled.div`
  width: 100%;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.43;
  color: ${props => (props.deliveryModeB2B ? '#FFF' : '#20357B')};
  display: flex;
  flex-direction: row;
`

const StyledText = styled(Text)`
  margin: 0 auto;
  line-height: 1.43;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 1rem;
  font-weight: bold;
`

const AddressContent = styled.div`
  line-height: 1.2rem;
  display: inline-block;
  cursor: pointer;
  margin: 0 1rem 0 1rem;
  ${SCREEN_SIZE.From.Desktop} {
    margin: 0 1.5rem 0 1rem;
    line-height: 1.875rem;
  }
`

const StyledExpandIcon = styled(Expand)`
  width: 1rem;
  height: 1rem;
  color: ${props => (props.deliveryModeB2B ? '#FFF' : '#20357B')};
`

class DeliveryModeSwitcherForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisiblePopover: false,
    }

    this.handleTogglePopover = this.handleTogglePopover.bind(this)
  }

  handleTogglePopover() {
    this.state.isVisiblePopover
      ? this.setState({ isVisiblePopover: false })
      : this.setState({ isVisiblePopover: true })
  }

  render() {
    const { deliveryModeB2B } = this.props
    const { isVisiblePopover } = this.state
    const b2bConfig = getExpConfig(SPLIT_FEATURES.B2B_CONFIG) || {}
    const deliveryModeContent = JSON.parse(b2bConfig?.config || '{}')
    return (
      <React.Fragment>
        {isVisiblePopover && (
          <DeliveryModeSwitchPopup
            deliveryModeB2B={deliveryModeB2B}
            deliveryModeContent={deliveryModeContent}
            onHandleClose={this.handleTogglePopover}
            callSetIdentify={this.props?.callSetIdentify}
          />
        )}
        <StyledPcmForm>
          <SelectContainer
            deliveryModeB2B={deliveryModeB2B}
            onClick={this.handleTogglePopover}
          >
            <AddressContent>
              <TextIcon
                suffix={<StyledExpandIcon deliveryModeB2B={deliveryModeB2B} />}
              >
                <StyledText data-testid="deliveryModeText">
                  {deliveryModeB2B ? (
                    <Fragment>{deliveryModeContent.b2bTitle}</Fragment>
                  ) : (
                    <Fragment>{deliveryModeContent.b2cTitle}</Fragment>
                  )}
                </StyledText>
              </TextIcon>
            </AddressContent>
          </SelectContainer>
        </StyledPcmForm>
      </React.Fragment>
    )
  }
}

const DeliveryModeSwitcherFormWrapper = props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <DeliveryModeSwitcherForm {...props} remoteConfig={remoteConfig} />
    )}
  </GlobalContext.Consumer>
)

export default DeliveryModeSwitcherFormWrapper
